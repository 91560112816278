h1 { display: block; font-size: 2em; }
h2 { display: block; font-size: 1.5em; }
h3 { display: block; font-size: 1.17em; }
/* h1 { display: block; font-size: 2em; margin-top: 0.67em}
h2 { display: block; font-size: 1.5em; margin-top: 0.83em;}
h3 { display: block; font-size: 1.17em; margin-top: 1em;} */
h4 { display: block; font-size: 1em; }
h5 { display: block; font-size: .83em; }
h6 { display: block; font-size: .67em;}
  ol,ul,dl {
      margin-left:2em;
  }
  ol li {
      list-style: decimal outside;
  }
  ul li {
      list-style: disc outside;
  }
  .youtubeIframe iframe{
    width:100%;
    height: 80vh
  }
  .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider.animated{
    /* transform: none; 
    transform: translate3d(0px, 0px, 0px);
    transition-duration: none!important; */
  }
  .carousel.carousel-slider .slider-wrapper.axis-horizontal .slider.animated li::marker {
    content: "";
  } 

  /* Override the default arrow styles */
.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 40px!important;
  line-height: 1;
  opacity: 1!important;
  color: #ffffff!important;
  background-color:#0c7348!important;
  padding: 6px 3px 1px 3px ;
  border-radius: 40px!important;
}

/* Optional: Custom background or border */
.slick-prev,
.slick-next {
  background-color: black; /* Add a background color if needed */
  border-radius: 50%; /* Make the arrow buttons circular */
}

/* Optional: Positioning the arrows differently */
.slick-prev {
  left: -15px!important;
}

.slick-next {
  right: 15px!important;
}
