.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.studentTableContainer{
  background:  #ffffff;
  height: 80vh;
  overflow-y: scroll!important;
}
.studentTableContainer td{
  max-width: 250px;
  min-width: 200px;
  overflow-x: hidden;
  text-wrap: wrap;
}
.studentTableContainer thead{
  position:sticky;
  top: 0;
  z-index: 10;
  background:  #edf2f7;
}
.studentTableContainer th{
  position:sticky;
  left: 0;
  width: auto;
  background: #edf2f7;
  z-index: 10;
  cursor: pointer;
}

.caseloadContainer{
  background:  #ffffff;
  height: 80vh;
  overflow-y: scroll!important;
}
.caseloadContainer td{
  max-width: 250px;
  min-width: auto;
  overflow-x: hidden;
  text-wrap: wrap;
}
.caseloadContainer thead{
  position:sticky;
  top: 0;
  z-index: 10;
  background:  #edf2f7;
}
.caseloadContainer th{
  position:sticky;
  left: 0;
  width: auto;
  background: #edf2f7;
  z-index: 10;
  cursor: pointer;

}
.sessionNotesTableContainer{
  background:  #ffffff;
  height: 80vh;
  overflow-y: scroll!important;
}
.sessionNotesTableContainer td{
  max-width: 100px;
  min-width: 100px;
  width:100px;
  overflow-x: hidden;
  text-wrap: wrap;
  padding:6px;
}
.largeSessionNoteColumn {
  max-width: 400px!important;
  min-width: 400px!important;
}
.smallSessionNoteColumnHeaders{
  max-width: 126px!important;
  min-width: 126px!important;
  padding: 6px!important;
}
.smallSessionNoteColumns{
  max-width: 126px!important;
  min-width: 126px!important;
  padding: 6px!important;
}
.tomsRatingTableContainer{
  background:  #ffffff;
  height: 80vh;
  overflow-y: scroll!important;
}
.tomsRatingTableContainer td{
  max-width: 250px;
  min-width: 200px;
  overflow-x: hidden;
  text-wrap: wrap;
}
.tomsRatingTableContainer thead{
  position:sticky;
  top: 0;
  z-index: 10;
  background:  #edf2f7;
}
.tomsRatingTableContainer th{
  position:sticky;
  left: 0;
  width: auto;
  background: #edf2f7;
  z-index: 10;
  cursor: pointer;
}
.clinicalClientContract{
/* background-color: pink; */
}
.clinicalClientContract table{
  width: 100%!important;
}
/* .overlay {
  z-index: 1;  
  background-color: transparent;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width:100%;
} */